<template>
  <v-container>
    <v-row class="text-center">

      <v-col class="align-items-center">
         <v-img class="logo" width="400"  :src="item"/>
        <h1 style="padding-top:100px;" class="text">
          Hoşgeldiniz, {{name}}
        </h1>
        <span>Şifrenizi girin</span>
        <v-otp-input dark type="password" class="input" length="4"></v-otp-input>
        <v-btn class="btn">GİRİŞ YAP</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      name: 'Doğukan Göker',
      item: require('@/assets/fleeca.png'),    
    }),
  }
</script>

<style>
.logo{
  margin-top: 5%;
  margin-left: 40%;
}
.text{
  font-weight: bold;
  padding-top: 30%;
}
.input{
  padding-left: 35%;
  width: 65%;
}
.btn{
  margin-top: 10px;
}
</style>
